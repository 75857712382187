import { Box, Grid, Paper, Typography } from '@material-ui/core';
import { graphql, PageProps } from 'gatsby';
import React from 'react';
import MarkdownContent from '../components/mol.markdown-content/markdown-content.component';
import PageLayout from '../components/org.page-layout/page-layout.component';
import { GatsbyImage } from 'gatsby-plugin-image';
import Seo from '../components/seo';
import { Spacing } from '../components/obj.constants/constants';

const AboutUsPage = (props: PageProps) => {
  const aboutUsPageData = (props.data as any).markdownRemark as any;
  const aboutUsPageMetadata = aboutUsPageData.frontmatter;
  const aboutUsPageBody = aboutUsPageData.html;
  return (
    <>
      <Seo title={aboutUsPageMetadata.aboutUsPageTitle} />
      <Box
        paddingTop={Spacing.XLarge}
        paddingBottom={Spacing.XLarge}
        paddingLeft={Spacing.Large}
        paddingRight={Spacing.Large}
      >
        <Grid container spacing={2}>
          <Grid item xs={1} lg={2} />
          <Grid item xs={10} lg={8}>
            <Typography variant="h1">
              {aboutUsPageMetadata.aboutUsPageTitle}
            </Typography>
          </Grid>

          <Grid item xs={1} lg={2} />
          <Grid item container xs={12} justify="center" alignItems="center">
            <GatsbyImage
              image={
                aboutUsPageMetadata.aboutUsPageMainImage.childImageSharp
                  .gatsbyImageData
              }
              alt="About us image"
            />
          </Grid>

          <Grid item xs={1} lg={2} />
          <Grid item xs={10} lg={8}>
            <MarkdownContent innerHTML={aboutUsPageBody} />
          </Grid>

          <Grid item xs={1} lg={2} />
          <Grid item xs={12} />
        </Grid>
      </Box>
    </>
  );
};

export const query = graphql`
  query AboutUsPageQuery {
    markdownRemark(frontmatter: { fileName: { eq: "aboutUsPage" } }) {
      frontmatter {
        aboutUsPageTitle
        aboutUsPageMainImage {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      html
    }
  }
`;

export default AboutUsPage;
